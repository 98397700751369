import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  AppStateService,
  ILoggedInHeaders,
  LoginSource,
  PersonalisationAPIService,
  ProfileService,
  SegmentService,
  SessionService,
  SignInService,
  SiteConfigService,
  StorageService,
} from '@services';
import {
  FavoriteSaveResponseDto,
  FavoritesConfig,
  FavoritesFundRequestTypeDto,
  FavoritesRequestDetail,
  FavoritesStatus,
  FundShareClassId,
  GlobalId,
  PersonalisationFavoriteData,
  PersonalisationPersonalData,
  SegmentId,
} from '@types';
import { ADD_TO_FAV_CLICKED } from '@utils/app.constants';
import { Logger } from '@utils/logger';
import dayjs from 'dayjs';
import { Observable, of, Subject, combineLatest, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

const logger = Logger.getLogger('FavoritesService');

export interface FavoriteSavedResult {
  action: 'add' | 'delete';
  fund: FundShareClassId;
  fundName: string;
  isError?: boolean;
}

/**
 * Favorites Service to support different components and different implementations for different sites.
 *
 * TODO May need to make methods product type aware.
 * TODO implement external storage service when available.
 */
@Injectable({
  providedIn: 'root',
})
export class FavoritesService implements OnDestroy {
  private favoritesConfig: FavoritesConfig;
  private favorites: PersonalisationFavoriteData[] = [];
  private favorites$: ReplaySubject<
    PersonalisationFavoriteData[]
  > = new ReplaySubject<PersonalisationFavoriteData[]>(1);

  private favoritesOnLoad$: ReplaySubject<
    PersonalisationFavoriteData[]
  > = new ReplaySubject<PersonalisationFavoriteData[]>(1);
  private favSavedResult$: Subject<FavoriteSavedResult> = new Subject<FavoriteSavedResult>();
  private unsubscribe$: Subject<void> = new Subject<void>();
  private segmentId: SegmentId;
  private globalId: GlobalId;
  private favoriteApiheader: ILoggedInHeaders;

  constructor(
    private appStateService: AppStateService,
    private siteConfig: SiteConfigService,
    private storageService: StorageService,
    private profileService: ProfileService,
    private signInService: SignInService,
    private httpClient: HttpClient,
    private sessionService: SessionService,
    private segmentService: SegmentService,
    private personalisationService: PersonalisationAPIService
  ) {
    this.favoritesConfig = this.appStateService.getFavoritesConfig();
    if (this.favoritesConfig.hasFavorites) {
      this.hasFavorites$()
        ?.pipe(takeUntil(this.unsubscribe$))
        .subscribe((hasFavorites) => {
          if (!hasFavorites) {
            this.favorites = [];
            this.favorites$.next(this.favorites);
          }
        });
    }
    this.personalisationService
      .getPersonalData$()
      ?.pipe(takeUntil(this.unsubscribe$))
      ?.subscribe((data: PersonalisationPersonalData) => {
        this.globalId = data?.identifiers?.globalId;
        this.favoriteApiheader = {
          globalId: data?.identifiers?.globalId,
          firmGlobalId: data?.firmGlobalId,
          expressNo: data?.identifiers?.expressNumber,
        };
        // load favorites from here
        if (this.isFavouriteEnabled() && data?.focusList?.length > 0) {
          const activeFavorites: PersonalisationFavoriteData[] = data.focusList.filter(
            (focusList) =>
              focusList.fundShareClassId &&
              focusList.status === FavoritesStatus.ACTIVE
          );

          this.loadFavorites(activeFavorites);
        } else {
          this.loadFavorites([]);
        }
        this.storageService
          .retrieve(ADD_TO_FAV_CLICKED, true)
          .then((fundDetails: FavoritesRequestDetail) => {
            if (fundDetails?.fundShareClassId) {
              this.toggleFavorite(fundDetails);
              this.storageService.remove(ADD_TO_FAV_CLICKED, true);
            }
          });
      });
    this.segmentService
      .getCurrentSegmentId$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((segment: SegmentId) => (this.segmentId = segment));
  }

  public isFavouriteEnabled(): boolean {
    // TODO : will enable the favorites in BR on segment level
    return (
      this.favoritesConfig.hasFavorites &&
      this.siteConfig.common?.enableFavoriteFunds &&
      (this.segmentId !== SegmentId.INVESTOR ||
        this.siteConfig.isSiteInternational())
    );
  }

  public getFavoriteConfig(): FavoritesConfig {
    return this.favoritesConfig;
  }

  public hasFavorites$(): Observable<boolean> {
    return combineLatest([
      this.profileService.getUserProfile$(),
      this.personalisationService.isIdentified$(),
      of(this.isFavouriteEnabled()),
    ]).pipe(
      map(([userProfile, isIdentified, isEnabled]) => {
        return (
          isEnabled &&
          ((userProfile.isLoggedIn &&
            // for bypass user we are not calling the personalisation so has favorites will be false
            userProfile.loginSource !== LoginSource.BYPASS) ||
            isIdentified)
        );
      })
    );
  }

  public getFavorites$(): Observable<PersonalisationFavoriteData[]> {
    return this.favorites$.asObservable();
  }

  public getFavoritesOnLoad$(): Observable<PersonalisationFavoriteData[]> {
    return this.favoritesOnLoad$.asObservable();
  }

  public isFavorite$(fund: FundShareClassId): Observable<boolean> {
    return this.getFavorites$().pipe(
      map((favorites) => favorites.some((fav) => fav.fundShareClassId === fund))
    );
  }

  public getFavSavedResult$(): Observable<FavoriteSavedResult> {
    return this.favSavedResult$.asObservable();
  }

  public hideSignModalAfterLogin$(): Observable<boolean> {
    return combineLatest([
      this.signInService.getSignInModal$(),
      this.profileService.isLoggedIn$(),
    ]).pipe(
      map((latestLogin) => {
        const [isSignInModalSet, isProfileLoggedIn] = latestLogin;
        return isSignInModalSet && isProfileLoggedIn;
      })
    );
  }

  public toggleFavorite(productData: FavoritesRequestDetail): void {
    const index = this.favorites.findIndex(
      (fav) => fav.fundShareClassId === productData.fundShareClassId
    );
    const requestBody: FavoritesFundRequestTypeDto = {
      CONTACT_GLOBAL_ID: this.globalId,
      TIS_SHARECLASS_CODE: productData.fundShareClassId,
      PRODUCT_TYPE: productData.productType,
      FUND_NAME: productData.fundName,
      FUND_URL: productData.fundUrl,
      ADDEDBY: 'user',
      UPDATEDON: dayjs().utc().format('DD-MMM-YYYY'),
      STATUS: index !== -1 ? FavoritesStatus.INACTIVE : FavoritesStatus.ACTIVE,
    };
    this.saveFavorites$(requestBody).subscribe((saved) => {
      if (saved && index !== -1) {
        this.favorites.splice(index, 1); // splice only if condition is true
        this.favorites$.next(this.favorites);
      }
      if (saved && index === -1) {
        const favPush: PersonalisationFavoriteData = {
          productType: requestBody.PRODUCT_TYPE,
          status: requestBody.STATUS,
          fundShareClassId: requestBody.TIS_SHARECLASS_CODE,
          updatedOn: requestBody.UPDATEDON,
          addedby: requestBody.ADDEDBY,
          fundName: requestBody.FUND_NAME,
          fundUrl: requestBody.FUND_URL,
        };
        this.favorites.push(favPush); // push only if condition is true
        this.favorites$.next(this.favorites);
      }
    });
  }

  private saveFavorites$(
    requestBody: FavoritesFundRequestTypeDto
  ): Observable<boolean> {
    const result: FavoriteSavedResult = {
      action:
        requestBody.STATUS === FavoritesStatus.INACTIVE ? 'delete' : 'add',
      fund: requestBody.TIS_SHARECLASS_CODE,
      fundName: requestBody.FUND_NAME,
      isError: false,
    };
    return new Observable((observer) => {
      if (this.favoritesConfig.useLocalStorage) {
        this.storageService.storeFundFavorites(this.favorites);
        this.favSavedResult$.next(result);
        observer.next(true);
        observer.complete(); // assuming it will be true always for local starage
      } else {
        this.saveFavoritesFromAPI$(requestBody)
          .then((response: FavoriteSaveResponseDto) => {
            if (response?.Statuscode === 200) {
              this.favSavedResult$.next(result);
              observer.next(true);
              observer.complete(); // will be set true if API response is Success
            }
          })
          .catch((error) => {
            result.isError = true;
            this.favSavedResult$.next(result);
            logger.error(error);
            observer.next(false);
            observer.complete();
          });
      }
    });
  }

  private loadFavorites(activeFavorites?: PersonalisationFavoriteData[]) {
    if (this.favoritesConfig.useLocalStorage) {
      this.storageService
        .retrieveFundFavorites()
        .then((favorites?: PersonalisationFavoriteData[]) => {
          if (favorites) {
            this.favorites = favorites;
          } else {
            this.favorites = [];
          }
          this.favorites$.next(this.favorites);
          this.favoritesOnLoad$.next(this.favorites);
        });
    } else {
      this.favorites = activeFavorites;
      this.favorites$.next(this.favorites);
      this.favoritesOnLoad$.next(this.favorites);
    }
  }

  private async saveFavoritesFromAPI$(
    requestBody: FavoritesFundRequestTypeDto
  ): Promise<FavoriteSaveResponseDto> {
    const authToken = await this.sessionService.getPersonalisationApiAccessToken();
    const httpOptions: { [k: string]: HttpHeaders } = {};
    httpOptions.headers = new HttpHeaders({
      expressNo: this.favoriteApiheader.expressNo,
      globalId: this.favoriteApiheader.globalId,
      firmGlobalId: this.favoriteApiheader.firmGlobalId,
      Authorization: `Bearer ${authToken}`,
    });
    return this.httpClient
      .post('/api/personalisation/v2/focusList', requestBody, httpOptions)
      .toPromise();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
